import React, { useMemo } from 'react'
import { useLocation, useHistory, matchPath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FeedTabsId } from 'enums'
import { APP_URL } from 'config/app'
import { ContentContainer } from 'layout'
import { FeedNav } from 'components'
import { PostForm } from 'containers'
import { useSetQueriesDataPostsCommunity, useSetQueriesDataPostsUser } from 'containers/Community/hooks'
import { getURLSearchParams } from 'utils/helpers'
import { useAppSelector } from 'store'
import {
    FeedPostsCommunity,
    FeedPostsUser,
    FeedPostsLeaders,
    FeedPostsPresident,
} from './components'
import style from './Feed.module.css'

const POSTS_LIMIT = 10
const POSTS_TILE_LIMIT = 18

const Feed: React.FC = () => {
    const { t } = useTranslation()
    const { search, pathname } = useLocation()
    const history = useHistory()

    const { tabId } = getURLSearchParams(search)
    const isLentaUser = matchPath(pathname, { path: APP_URL.lentaUser })

    const { feedPostTile: isPostTile } = useAppSelector((state) => state.app.settings)
    const user = useAppSelector((state) => state.user)
    const isMlmUser = useAppSelector((state) => state.mlm)

    const { invalidateQueries: invalidateQueriesPostsCommunity } = useSetQueriesDataPostsCommunity()
    const { invalidateQueries: invalidateQueriesPostsUser } = useSetQueriesDataPostsUser()

    const tabs = useMemo(() => {
        if (isLentaUser) {
            return [
                { id: FeedTabsId.userPosts, name: t('user_profile_posts') },
                { id: FeedTabsId.friends, name: t('user_profile_friends') },
            ]
        }
        if (isMlmUser) {
            return [
                { id: FeedTabsId.allPosts, name: t('feed_all_articles') },
                { id: FeedTabsId.companyPosts, name: t('company_post') },
                { id: FeedTabsId.leadersPosts, name: t('leaders_posts') },
                { id: FeedTabsId.presidentPosts, name: t('president_posts') },
            ]
        }

        return []
    }, [isMlmUser, pathname])

    const activeTabId: number = useMemo(() => {
        if (tabId === undefined) {
            if (isLentaUser) {
                return FeedTabsId.userPosts
            }
            return FeedTabsId.allPosts
        }
        return Number(tabId)
    }, [tabId, pathname])

    const handlerChangeTab = (id: number) => {
        if (id !== activeTabId) {
            switch (id) {
                case FeedTabsId.userPosts:
                    history.push(APP_URL.lentaUser)
                    break
                case FeedTabsId.friends:
                    history.push(APP_URL.friends)
                    break
                default:
                    history.replace(`${APP_URL.lenta}?tabId=${id}`)
            }
        }
    }

    const handlerCompletePost = () => {
        if (activeTabId === FeedTabsId.allPosts || activeTabId === FeedTabsId.companyPosts) {
            invalidateQueriesPostsCommunity({}, { exact: false })
        }
        if (activeTabId === FeedTabsId.userPosts
            || activeTabId === FeedTabsId.friends
            || activeTabId === FeedTabsId.presidentPosts
        ) {
            invalidateQueriesPostsUser({ userId: user.id }, { exact: false })
        }
    }

    return (
        <ContentContainer classes={style.content} size="half">
            <PostForm
                onComplete={handlerCompletePost}
            />
            <FeedNav
                classesItem={style.tabsItem}
                active={activeTabId}
                items={tabs}
                onChange={handlerChangeTab}
            />
            <div className={style.feed}>
                {activeTabId === FeedTabsId.allPosts && (
                    <FeedPostsCommunity
                        isPostTile={!!isPostTile}
                        queryParams={{ limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
                {activeTabId === FeedTabsId.userPosts && (
                    <FeedPostsUser
                        isPostTile={!!isPostTile}
                        queryParams={{ userId: user.id, limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
                {activeTabId === FeedTabsId.companyPosts && (
                    <FeedPostsCommunity
                        isPostTile={!!isPostTile}
                        queryParams={{ group: '230,55,56,11', limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
                {activeTabId === FeedTabsId.leadersPosts && (
                    <FeedPostsLeaders
                        isPostTile={!!isPostTile}
                        queryParams={{ limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
                {activeTabId === FeedTabsId.presidentPosts && (
                    <FeedPostsPresident
                        isPostTile={!!isPostTile}
                        queryParams={{ limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
            </div>
        </ContentContainer>
    )
}

export default Feed
